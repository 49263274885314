<template>
  <div>
    <a-spin :spinning="loading">
      <div class="iotplt-card-box">
        <a-row :gutter="[8,16]">
          <a-col :span="8">
            <a-descriptions
              :column="1"
              class="iotplt-descriptions iotplt-descriptions-max-6"
            >
              <a-descriptions-item label="API权限">
                {{ info.is_api_enabled_display }}
              </a-descriptions-item>

              <a-descriptions-item
                label="API ID"
                v-if="info.is_api_enabled"
              >
                {{ info.api_id }}
                <a-tooltip>
                  <template slot="title">
                    点击复制
                  </template>
                  <a-icon
                    class="iotplt-cursor-pointer"
                    type="copy"
                    theme="twoTone"
                    v-clipboard:copy="info.api_id"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onCopyError"
                  />
                </a-tooltip>
              </a-descriptions-item>

              <a-descriptions-item
                label="API SECRET"
                v-if="info.is_api_enabled"
              >
                {{ this.$lodash.truncate(info.api_secret, { length: 20, omission: '...'}) }}
                <a-tooltip>
                  <template slot="title">
                    点击复制
                  </template>
                  <a-icon
                    class="iotplt-cursor-pointer"
                    type="copy"
                    theme="twoTone"
                    v-clipboard:copy="info.api_secret"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onCopyError"
                  />
                </a-tooltip>
              </a-descriptions-item>
            </a-descriptions>
          </a-col>

          <a-col :span="8" v-if="info.is_api_enabled">
            <a-descriptions
              :column="1"
              class="iotplt-descriptions iotplt-descriptions-max-8"
            >
              <a-descriptions-item label="调用API的IP地址1">
                {{ info.api_ip_1 }}
              </a-descriptions-item>

              <a-descriptions-item label="调用API的IP地址2">
                {{ info.api_ip_2 }}
              </a-descriptions-item>

              <a-descriptions-item label="调用API的IP地址3">
                {{ info.api_ip_3 }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>

          <a-col :span="8" v-if="info.is_api_enabled">
            <a-descriptions
              :column="1"
              class="iotplt-descriptions iotplt-descriptions-max-8"
            >
              <a-descriptions-item label="查询接口调用限制">
                {{ info.api_query_rate }}
              </a-descriptions-item>

              <a-descriptions-item label="操作接口调用限制">
                {{ info.api_operation_rate }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
        </a-row>
      </div>

    </a-spin>

    <div
      v-if="info.is_api_enabled"
      class="iotplt-card-box api-list-box"
    >
      <a-descriptions
        title="API 列表"
      />

      <agent-api-list :agent-id="this.agentId" />
    </div>
  </div>
</template>

<script>
import { findAgentApiInfo } from '@/api/agent'
import AgentApiList from '@/views/api/List'

export default {
  name: 'ApiInfo',
  components: {
    AgentApiList
  },
  data() {
    return {
      info: {},
      loading: true
    }
  },
  computed: {
    agentId() {
      return this.$store.getters.userAgentId
    }
  },
  created() {
    this.fetchApiInfo()
  },
  methods: {
    fetchApiInfo() {
      this.loading = true
      findAgentApiInfo(this.agentId).then((res) => {
        if (res.code === 0) {
          this.info = res.data
        }
        this.loading = false
      })
    },

    onCopy: function(e) {
      this.$message.success('复制成功！')
    },

    onCopyError: function(e) {
      this.$message.error('复制失败！')
    }

  }
}
</script>

<style lang="less" scoped>
.api-list-box {
  margin-top: 20px;
}
</style>

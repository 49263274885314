<template>
  <div>
    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
    />

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/index'
import { findAgentsApiInterfaces } from '@/api/agents_api_interface'

export default {
  name: 'ApiList',
  components: {
    Pagination
  },
  props: {
    agentId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: [],
      loading: true,
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '接口名称',
          dataIndex: 'name'
        },
        {
          title: '接口类型',
          dataIndex: 'api_type'
        },
        {
          title: '是否开通',
          dataIndex: 'is_open_display'
        }
      ]
    }
  },
  methods: {
    fetchData() {
      this.loading = true
      findAgentsApiInterfaces(Object.assign({ agent_id: this.agentId }, this.query)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>

import request from '@/utils/request'

// 客户API列表
export function findAgentsApiInterfaces(params) {
  return request({
    url: `/agents_api_interfaces`,
    method: 'get',
    params: params
  })
}
